import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Promotions from './promotions'
import { Divider } from './divider'
import { EmptyPromotionInventoryCard } from './empty-promotion-inventory-card'
import { PromotionCard } from './promotion-card'

export function PromotionCampaigns() {
  const i18n = I18n.useI18n()

  const activePromotions = ReactRedux.useSelector(state =>
    Promotions.getActivePromotions(state.promotions, { dateNow: Date.now() })
  ).filter(item => !item?.tags?.includes('black-week'))

  const futurePromotions = ReactRedux.useSelector(state =>
    Promotions.getFuturePromotions(state.promotions, { dateNow: Date.now() })
  ).filter(item => !item?.tags?.includes('black-week'))

  return (
    <React.Fragment>
      {R.isEmpty(activePromotions) ? (
        <EmptyPromotionInventoryCard translationKey="promotion-notifications.none-active" />
      ) : (
        <Common.Box display="grid" gridGap={0} pb={1}>
          {R.map(
            item => (
              <Common.Timestamp key={item.key}>
                {timestamp => (
                  <PromotionCard
                    dateNow={timestamp}
                    activeTo={item.activeTo}
                    campaign={item.campaign}
                    name={item.name}
                    ctaLink={item.loggedInButtonLink}
                    id={item.key}
                    seen={item.seen}
                  />
                )}
              </Common.Timestamp>
            ),
            R.reverse(R.sortBy(R.path(['activeFrom']), activePromotions))
          )}
        </Common.Box>
      )}
      {!R.isEmpty(futurePromotions) && (
        <Common.Box flexShrink="0" pb={1}>
          <Common.Space pt={1} pb={1}>
            <Divider />
          </Common.Space>
          <Common.Box pb={1} fontSize={3} fontWeight="bold" textAlign="center">
            {i18n.translate('promotion-notifications.upcoming.title')}
          </Common.Box>
          <Common.Box display="grid" gridGap={0}>
            {R.map(
              item => (
                <Common.Timestamp key={item.key}>
                  {timestamp => (
                    <PromotionCard
                      upcoming
                      dateNow={timestamp}
                      activeTo={item.activeTo}
                      activeFrom={item.activeFrom}
                      campaign={item.campaign}
                      ctaLink={item.loggedInButtonLink}
                      id={item.key}
                      seen={item.seen}
                    />
                  )}
                </Common.Timestamp>
              ),
              R.reverse(R.sortBy(R.path(['activeFrom']), futurePromotions))
            )}
          </Common.Box>
        </Common.Box>
      )}
    </React.Fragment>
  )
}
